.dashboard-container {
  background: #f3f5ff;
  width: 96.3%;
  min-height: 100vh;
  margin: 0 auto;
  margin-bottom: -70px;
  padding-top: 50px;
  padding-left: 40px;
  padding-bottom: 20px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dash-header {
  font-family: "Open-Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 48px;
}

.time {
  font-family: "Open-Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #737373;
  line-height: 24px;
  display: flex;
  column-gap: 10px;
  margin-top: 5px;
}

.copyright-text {
  color: #696969;
  margin-top: 38px;
  font-size: 16px;
  font-family: "Open-Sans";
  font-weight: 500;
  line-height: 40px;
}

.form-control-label {
  color: #0e0e0e;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.card-bg {
  width: 94%;
  height: 185px;
  margin-top: 65px;
  background: inherit;
}

.cards-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.cards {
  background: inherit;
  width: 20%;
  height: 188px;
  margin-top: 21px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  column-gap: 15px;
  border-radius: 20px;
  border: 1px dashed rgba(115, 115, 115, 0.4);
  box-sizing: border-box;
}

.card-img {
  width: 60px;
  /* margin-bottom: 38px; */
}

.card-info {
  width: 100%;
  font-family: "Poppins";
  font-style: normal;
  line-height: 36px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 30px;
}

.card-title {
  color: #000;
  height: 32px;
  padding: 0px 12px;
  display: flex;
  align-items: center;
  font-family: "Open-Sans";
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  border-radius: 43.31px;
}

/* Rules Section */

.rule-container {
  width: 94%;
  margin-top: 50px;
}

.ruleSecImg {
  background-image: url("../assets/svg/ruleSecBg.svg");
  background-repeat: no-repeat;
  width: 100%;
  background-position: center;
  background-size: 30%;
  padding-top: 50px;
}

.rule-header {
  display: flex;
  justify-content: space-between;
}

.rule-header > h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height */

  color: #000000;
}

.btn-container > span {
  margin-right: 20px;
  position: relative;
  top: 6px;
  color: #0e0e0e;
}

.btn-active-container {
  color: white;
}

.btn-active-container > span {
  margin-right: 20px;
  position: relative;
  top: 6px;
  color: white;
  color: #0e0e0e;
}

.rule-active-btn {
  background-color: #ff543d;
}

.rule-single-card {
  width: 100%;
  height: 482px;
  background: #ffffff;
  border-radius: 20px;
  margin-top: 24px;
}

.rule-text {
  text-align: center;
  padding-top: 10%;
}

.card-rule-header {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
  margin-bottom: 24px;
}

.rule-subheader {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  /* or 188% */
  color: #737373;
  width: 40%;
  margin: 0 auto;
  margin-bottom: 46px;
}

.date-input {
  box-sizing: border-box;
  width: 200px;
  height: 48px;
  left: 790px;
  top: 986px;
  border: 2px solid #255cff;
  border-radius: 20px;
  padding: 20px;
  font-family: "Poppins";
  font-weight: "600";
  background: #f4f1ec;
  cursor: pointer;
  outline-style: none;
}

.traffic-btn {
  background: #fff;
  color: #0e0e0e;
  width: 130px;
  height: 42px;
  border: none;
  font-family: "Open-Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  border: 1px solid #d9d9d9;
  border-radius: 10px;

  cursor: pointer;
}

.traffic-btn:active {
  background: #255cff;
  color: white;
  font-weight: 600;
  -webkit-box-shadow: 0 10px 6px -6px #777;
  -moz-box-shadow: 0 10px 6px -6px #777;
  box-shadow: 0 10px 6px -6px #777;
}

.chart-card {
  width: 100%;
  min-height: 830px;
}

.chart-header {
  font-family: "Open-Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #737373;
  display: flex;
  justify-content: flex-end;
  column-gap: 56px;
  width: 95%;
  margin-top: 35px;
}

.transaction-container {
  background: #ffffff;
  width: 94%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  margin: 4em 0;
}

.transaction-header {
  color: #000000;
  margin: 42px 44px;
  font-family: "Open-Sans";
  font-size: 24px;
  font-weight: 600;
  font-style: normal;
}

.transaction-header-div {
  width: 100%;
  height: 117px;
  padding: 0px 10px;
  border-style: solid;
  border-width: 1px 0px;
  border-color: #d9d9d9;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}

.transaction-dropdown {
  color: #737373;
  width: 71px;
  height: 100%;
  border: 1px solid #081226;
  border-radius: 5px;
  outline: none;
  padding-left: 10px;
  padding-right: 10px;
}

.transaction-header-div-search {
  width: 116px;
  height: 35px;
  margin-right: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
}

.transaction-header-div-share-content {
  color: #fff;
  margin: 8px;
  cursor: pointer;
}

.transaction-header-div-search-input {
  color: #b5b5b5;
  width: 60px;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  border: none;
  outline: none;
}
.transaction-header-div-search-input::placeholder {
  color: #b5b5b5;
  font-size: 14px;
}

.transaction-header-status {
  color: #737373;
  width: 102px;
  height: 36px;
  margin-right: 7px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-family: "Open-Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  justify-content: center;
  cursor: pointer;
  outline: none;
}

.transaction-header-filter {
  color: #737373;
  width: 112px;
  height: 34px;
  padding: 0 8px;
  margin-right: 7px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Open-Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  justify-content: center;
  cursor: pointer;
  outline: none;
}

.transaction-header-filter-input {
  width: 88%;
  height: 100%;
  outline: none;
  border: none;
}

.transaction-header-filter-icon {
  background: #eef0f4;
  width: 24px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.transaction-header-status-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #255cff;
}
.transaction-header-div-date {
  width: 157px;
  height: 35px;
  margin-right: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.transaction-date-icon {
  background: #eef0f4;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.transaction-header-div-date p {
  color: #081226;
  margin-right: 0.5rem;
  font-family: "Open-Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.transaction-header-div-share {
  width: 45px;
  height: 35px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.transaction-header-div-share-cont {
  background: #255cff;
  min-width: 70px;
  min-height: 30px;
  margin-top: 1px;
  padding-top: 8px;
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  z-index: 2;
}

.transaction-table-header {
  color: #000000;
  height: 60px;
  font-family: "Open-Sans";
  font-size: 13px;
  font-weight: 700;
}

table {
  border-collapse: collapse;
  width: 100%;
}
th,
td {
  border-width: 1px 0px;
  border-style: solid;
  border-color: #d9d9d9;
}
.transaction-table-content {
  color: #737373;
  height: 69px;
  font-family: "Open-Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  line-height: 15px;
  box-sizing: border-box;
}

.transaction-table-content-data {
  min-width: 60px;
}

.transaction-table-pagination-container {
  width: 50%;
  height: 150px;
  padding-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.transaction-table-pagination {
  color: #255cff;
  height: 50px;
  margin-right: 48px;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  border: 1px solid #d9d9d9;
}

.transaction-table-btn {
  background: #255cff;
  color: #fff;
  width: 105px;
  height: 40px;
  font-family: "Poppins";
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.transaction-table-btn-disabled {
  color: #b5b5b5;
}

.transaction-table-btn-num {
  background: inherit;
  color: #ff543d;
  width: 50px;
  height: 100%;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d9d9d9;
  cursor: pointer;
}

.transaction-table-btn-focus {
  background: #ff543d;
  color: #ffffff;
}
.no-transaction-content {
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.no-transaction-content-header {
  color: #000000;
  margin-top: 16px;
  font-family: "Poppins";
  font-style: normal;
  font-size: 24px;
  font-weight: 600;
}

.no-transaction-content-text {
  color: #737373;
  width: 291px;
  margin-top: 10px;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  line-height: 30px;
}
