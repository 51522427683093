@tailwind base;
@tailwind components;
@tailwind utilities;
html {
  scroll-behavior: smooth;
}

.login {
  background: #f8f6e8;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0;
  padding: 0;
}
.login-right-form {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;
  border: 0.5px solid #c4c4c4;
  border-radius: 6px;
  background: white;
}
.login-right h3 {
  color: #595959;
  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 600;
  line-height: 26.04px;
  text-align: left;
  padding-bottom: 20px;
  color: #595959;
}
.login-right .form-group {
  display: flex;
  flex-direction: column;
  text-align: left;
}
.login-right .form-group label {
  font-family: "Poppins";
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 8px;
  color: #333333;
}
.login-right .form-group input {
  background: #fafafa;
  border: 0.5px solid #c4c4c4;
  border-radius: 6px;
  padding: 11px 0;
  padding-left: 19px;
  margin-bottom: 10px;
}
.login-right .form-group p {
  font-family: "Poppins";
  font-size: 14px;
  line-height: 22px;
  color: #969696;
}
.login-right button[type="submit"] {
  color: #ffffff;
  width: 100%;
  font-family: "Poppins";
  font-size: 18px;
  text-transform: uppercase;
  line-height: 20px;
  background: #01715d;
  border-radius: 6px;
  padding: 12px 0;
  margin-top: 20px;
  margin-bottom: 15px;
}
.login-right a {
  font-family: "Poppins";
  font-size: 14px;
  line-height: 22px;
  color: #595959;
  margin-bottom: 20px;
  transition: all 0.7s ease;
}
.login-right a:hover {
  text-decoration: underline;
}
.login-right p.or {
  font-family: "Poppins";
  font-size: 16px;
  line-height: 23px;
  color: #595959;
  margin-bottom: 20px;
}
.login-right .google {
  width: 100%;
  font-family: "Poppins";
  font-size: 14px;
  line-height: 22px;
  color: #595959;
  background: #ffffff;
  border: 0.5px solid #595959;
  border-radius: 6px;
  padding: 11px;
}
.login .toSignup {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  text-align: center;
  color: #595959;
  margin-top: 20px;
}
.login .toSignup a {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 600;
  margin-right: 5px;
  color: #2d8190;
  transition: all 0.7s ease;
}
.login .toSignup a:hover {
  text-decoration: underline;
}
.login-left article {
  position: absolute;
  left: 0;
  bottom: 0;
  overflow: hidden;
}
.login-left img {
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 1s linear;
}
.login-left img.activeSlide {
  opacity: 1;
  transform: translateX(0);
}
.login-left img.lastSlide {
  transform: translateX(-100%);
}
.login-left img.nextSlide {
  transform: translateX(100%);
}
.login-left .slider-details {
  color: white;
  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 600;
  line-height: 26px;
}
.login-left .control {
  width: 8px;
  height: 8px;
  background-color: #ffffff;
  margin-right: 1.5em;
  border-radius: 50%;
  transition: all 1s linear;
}
.login-left .activeControl {
  width: 35px;
  border-radius: 10px;
  background-color: #fcd55b;
}

.signup {
  background: #f8f6e8;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0;
  padding: 0;
}
.signup-right {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
@media (max-width: 1300px) {
  .signup-right {
    margin: 0;
  }
}
@media (min-width: 1301px) {
  .signup-right {
    margin: auto;
  }
}
.signup-right-form {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 465px;
  padding: 20px 40px;
  text-align: center;
  border: 0.5px solid #c4c4c4;
  border-radius: 6px;
  background: white;
}
@media (max-width: 560px) {
  .signup-right-form {
    width: 350px;
  }
}
.signup-right-form-nameFlex {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 560px) {
  .signup-right-form-nameFlex {
    flex-direction: column;
  }
}
.signup-right h3 {
  color: #595959;
  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 600;
  line-height: 26.04px;
  text-align: left;
  padding-bottom: 20px;
  color: #595959;
}
.signup-right .form-group {
  display: flex;
  flex-direction: column;
  text-align: left;
}
.signup-right .form-group label {
  font-family: "Poppins";
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 8px;
  color: #333333;
}
.signup-right .form-group input {
  background: #fafafa;
  border: 0.5px solid #c4c4c4;
  border-radius: 6px;
  padding: 11px 0;
  padding-left: 19px;
  margin-bottom: 10px;
}
.signup-right .form-group p {
  font-family: "Poppins";
  font-size: 14px;
  line-height: 22px;
  color: #969696;
}
.signup-right button[type="submit"] {
  color: #ffffff;
  width: 100%;
  font-family: "Poppins";
  font-size: 18px;
  text-transform: uppercase;
  line-height: 20px;
  background: #01715d;
  border-radius: 6px;
  padding: 12px 0;
  margin-top: 20px;
  margin-bottom: 15px;
}
.signup-right a {
  font-family: "Poppins";
  font-size: 14px;
  line-height: 22px;
  color: #595959;
  margin-bottom: 20px;
  transition: all 0.7s ease;
}
.signup-right a:hover {
  text-decoration: underline;
}
.signup-right p.or {
  font-family: "Poppins";
  font-size: 16px;
  line-height: 23px;
  color: #595959;
  margin-bottom: 20px;
}
.signup-right .google {
  width: 100%;
  font-family: "Poppins";
  font-size: 14px;
  line-height: 22px;
  color: #595959;
  background: #ffffff;
  border: 0.5px solid #595959;
  border-radius: 6px;
  padding: 11px;
}
.signup .toSignup {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  text-align: center;
  color: #595959;
  margin-top: 20px;
}
.signup .toSignup a {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 600;
  margin-right: 5px;
  color: #2d8190;
  transition: all 0.7s ease;
}
.signup .toSignup a:hover {
  text-decoration: underline;
}
.signup-left {
  margin-top: 20px;
}
.signup-left article {
  position: absolute;
  left: 0;
  bottom: 0;
  overflow: hidden;
}
.signup-left article img {
  width: 100%;
  height: 100%;
  margin-top: 20px;
  opacity: 0;
  transition: all 1s linear;
}
.signup-left article img.activeSlide {
  opacity: 1;
  transform: translateX(0);
}
.signup-left article img.lastSlide {
  transform: translateX(-100%);
}
.signup-left article img.nextSlide {
  transform: translateX(100%);
}
.signup-left .slider-details {
  color: white;
  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 600;
  line-height: 26px;
}
.signup-left .control {
  width: 8px;
  height: 8px;
  background-color: #ffffff;
  margin-right: 1.5em;
  border-radius: 50%;
  transition: all 1s linear;
}
.signup-left .activeControl {
  width: 35px;
  border-radius: 10px;
  background-color: #fcd55b;
}

.verify {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.verify-content {
  width: 430px;
  text-align: center;
}
.verify-content h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 108.5%;
  letter-spacing: -0.3px;
  margin: 12px 0;
  color: #333333;
}
.verify-content h6 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 108.5%;
  letter-spacing: -0.3px;
  margin: 13px 0;
  color: #333333;
}
.verify-content p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 159.5%;
  text-align: center;
  letter-spacing: -0.3px;
  margin: 12px 0;
  color: #333333;
}
.verify-content button[type="submit"] {
  color: #ffffff;
  width: 250px;
  font-family: "Poppins";
  font-size: 16px;
  line-height: 20px;
  background: #2d8191;
  border-radius: 6px;
  padding: 12px 0;
  margin-top: 20px;
  margin-bottom: 15px;
}

.ForgotPassword .forgotIcon {
  font-size: 80px;
  color: #01715d;
  text-align: center;
  margin: auto;
  background-color: #f7fff7;
  padding: 25px;
  border-radius: 50%;
  margin-bottom: 10px;
}
.ForgotPassword h2 {
  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 500;
  color: #595959;
}
.ForgotPassword p {
  font-family: "Poppins";
  font-size: 14px;
  color: #595959;
}
.ForgotPassword p span {
  color: #fcd55b;
  margin-left: 2px;
}
.ForgotPassword p span:hover {
  cursor: pointer;
  text-decoration: underline;
}
.ForgotPassword input {
  background: #fafafa;
  border: 0.5px solid #c4c4c4;
  border-radius: 6px;
}
.ForgotPassword button {
  font-family: "Poppins";
  font-size: 18px;
  padding: 12px;
  color: #ffffff;
  background-color: #01715d;
  border-radius: 6px;
  margin-top: 15px;
  margin-bottom: 10px;
}
.ForgotPassword a {
  font-family: "Poppins";
  font-size: 16px;
  color: #01715d;
  margin-top: 5px;
}
.ForgotPassword a:hover {
  text-decoration: underline;
}

/*# sourceMappingURL=auth.css.map */
