.priority-header {
  font-family: "Open-Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 48px;
}

.priority-header-text {
  color: #737373;
  max-width: 918px;
  margin-top: 18px;
  font-family: "Open-Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
}

.priority-content-container {
  background: #ffffff;
  width: 95%;
  margin-top: 35px;
  padding: 30px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.priority-content-img {
  width: 220px;
  margin-left: 15px;
}

.priority-card-label {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.priority-card {
  min-height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 9px;
  border: #737373;
  list-style: none;
}

.priority-card-item {
  background: #ececec;
  color: #000000;
  font-family: "Open-Sans";
  font-weight: 600;
  font-size: 18px;
  width: 163px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: move;
  border-radius: 10px;
}

.priority-card-label-title {
  color: #9f9f9f;
  height: 37px;
  margin-right: -40px;
  font-family: "Open-Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  transform: rotate(-90deg);
}
.priority-card-label-container {
  color: #bbbbbb;
  min-height: 350px;
  width: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 9px;
}

.priority-card-label-text {
  height: 100px;
  font-weight: 500;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.priority-vendor-details-text {
  color: #255cff;
  margin-top: 26px;
  font-family: "Open-Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
}

.priority-vendor-container {
  margin-top: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.priority-vendor-list-container {
  background: #f3f3f3;
  width: 470px;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 20px;
}

.vendor-list {
  background: #d9d9d9;
  width: 60px;
  height: 60px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.vendor-list-hover {
  background: rgba(255, 84, 61, 0.15);
  width: 60px;
  height: 60px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
